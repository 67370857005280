<div class="row animated fadeIn fast">


    <!-- Datos de la cabecera de la entrada-->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">
        <app-entrada-info [entrada]="entrada"></app-entrada-info>
    </div>


    <!-- Datos del detall, artículo -->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">

        <div class="card border border-info rounded">
            <div *ngIf="entrada.detall && entrada.detall[0]"
                 class="card-body">


                <div class="row">

                    <div class="col-md-7">
                        <h4 class="card-title text-info">Artículo {{detall.artSku}}
                            <span *ngIf="detall.swDefectuoso== Constantes.DEFECTUOSO"
                                  class="text-warning ml-12">
                                <i title="Defectuoso"
                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                            </span>
                        </h4>
                        <h6 class="card-subtitle">Datos del artículo</h6>

                        <ul>
                            <li class="card-text">{{detall.artDescripcion}}</li>
                            <li class="card-text"
                                [ngClass]="{'text-danger': enviado && f['cantidad'].errors}"><b>Cantidad:</b> {{detall.cantidad}}</li>
                            <li class="card-text"><b>En el palé:</b> {{detall.pale}}</li>
                        </ul>

                        <ul>
                            <li class="card-text"><b>lote:</b> {{detall.lote}}</li>
                            <li class="card-text"><b>Fecha de caducidad: </b>
                                <span [innerHTML]="detall.fecha_caducidad | iconFechaCaducada"></span>
                            </li>

                        </ul>

                    </div>

                    <div class="col-md-5">
                        <div class="text-center shadow mt-4"
                             style="display: flex; justify-content: center;">
                            <div style="display: flex; align-items: center;">
                                <img [src]="fotoDir +'/articulos/'+ detall.artFoto"
                                     loading="lazy"
                                     alt="Foto"
                                     title="{{detall.artDescripcion}}"
                                     class="img-thumbnail img-avatar mt-12">
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>

    </div>




    <!-- Pestañas -->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">
        <div class="card border border-info rounded">

            <div>
                <ul class="nav nav-tabs customtab"
                    role="tablist">

                    <li class="nav-item">
                        <a class="nav-link active"
                           data-toggle="tab"
                           href="#tabUbi1"
                           role="tab">
                            <span class="hidden-sm-up"><i class="ti-home"></i></span>
                            <span class="hidden-xs-down">
                                <span class="card-subtitle text-info">Ubicaciones con este artículo </span>
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link"
                           data-toggle="tab"
                           href="#tabUbi2"
                           role="tab">
                            <span class="hidden-sm-up"><i class="ti-user"></i></span>
                            <span class="hidden-xs-down">
                                <span class="card-subtitle text-info">Ubicaciones reservadas a este cliente</span>
                            </span>
                        </a>
                    </li>

                </ul>
            </div>

            <!-- Tab panes -->
            <div class="tab-content">

                <div class="tab-pane active"
                     id="tabUbi1"
                     role="tabpanel"
                     aria-expanded="true">
                    <!-- Lista de ubicaciones del artículo -->
                    <app-ubicaciones-articulo [padre]="this"
                                              [entrada]="entrada"
                                              [detall]="detall"> </app-ubicaciones-articulo>
                </div>

                <div class="tab-pane"
                     id="tabUbi2"
                     role="tabpanel"
                     aria-expanded="false">
                    <!-- Lista de ubicaciones del cliente -->
                    <app-ubicaciones-cliente [padre]="this"
                                             [entrada]="entrada"> </app-ubicaciones-cliente>
                </div>

            </div>
        </div>
    </div>







    <!-- Formulario de ubicación -->
    <div class="col-md-6 col-xs-12  animated fadeIn fast">

        <form class="form p-t-20"
              [formGroup]="ubiForm"
              (ngSubmit)="enviarUbi()"
              class="form-horizontal ">

            <div class="card border border-success rounded">

                <div class="card-body">
                    <h4 class="card-title text-info">Ubicación</h4>
                    <h6 class="card-subtitle"> </h6>
                    <div class="row">

                        <!-- ubicacion -->
                        <div class="col-md-8">
                            <div class="form-group"
                                 [ngClass]="{'has-danger': enviado && f['ubicacion'].errors }">
                                <label class="form-control-label">Ubicar artículo en:</label>
                                <input type="text"
                                       formControlName="ubicacion"
                                       (input)="cambiaUbi()"
                                       mask="00.00.00.00.00.00"
                                       class="form-control grande"
                                       style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                       [ngClass]="{ 'form-control-danger': enviado && f['ubicacion'].errors }">

                                <div *ngIf="enviado && f['ubicacion'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['ubicacion'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['ubicacion'].errors['minlength']">Deben ser 12 digitos</div>
                                    <div *ngIf="f['ubicacion'].errors['maxlength']">Deben ser 12 digitos</div>
                                    <div *ngIf="f['ubicacion'].errors['empiezaPor']">No se corresponde con el almacén de entrada. Debe empezar por {{entrada.almCodigo}}</div>
                                    <div *ngIf="f['ubicacion'].errors?.['apiError']">{{ubiForm.get('ubicacion')?.errors?.['apiError']}}</div>
                                </div>
                                <small>{{codUbiDecript}}</small><br>
                                <small class="text-secondary">{{infoAlmacenObj.nombre }}</small>
                            </div>
                        </div>

                        <!-- cantidad -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': enviado && f['cantidad'].errors }">
                                <label class="form-control-label">Cantidad:</label>
                                <input type="number"
                                       step="1"
                                       formControlName="cantidad"
                                       class="form-control grande"
                                       style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;"
                                       [ngClass]="{'form-control-danger': enviado && f['cantidad'].errors }">

                                <div *ngIf="enviado && f['cantidad'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['cantidad'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['cantidad'].errors['numEntero']">Debe ser un entero</div>
                                    <div *ngIf="f['cantidad'].errors['max']">Máximo {{detall.cantidad}} unidades</div>
                                    <div *ngIf="f['cantidad'].errors?.['apiError']">{{ubiForm.get('cantidad')?.errors?.['apiError']}}</div>
                                </div>
                            </div>
                        </div>


                    </div> <!-- row -->


                </div>

                <span *ngIf="!produccion">
                    <pre>
                        {{f['ubicacion'].errors |  json }} 
                        {{f['cantidad'].errors |  json }} 
                    </pre>
                </span>


                <div class="card-footer">

                    <div class="d-flex">
                        <button [disabled]="loading"
                                type="button"
                                class="btn waves-effect waves-light btn-rounded btn-secondary"
                                [routerLink]="'/panel/entradas_lista'">Cancelar</button>

                        <button [disabled]="loading"
                                type="submit"
                                class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                            <i *ngIf="loading"
                               class="fa fa-spinner fa-spin"></i>
                            Enviar</button>
                    </div>

                </div><!-- card footer -->

            </div>
        </form>

    </div>







</div>
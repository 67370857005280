<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Confirmar entrada PDA</h3>
        <h3 class="text-themecolor monospace">
            Desde playa {{entrada.ubiPlaya! | mask: '00.00.00.00.00.00'}}
        </h3>
    </div>
</div>

<div class="row animated fadeIn fast">


    <!-- Formulario -->
    <div class="col-lg-12">
        <div class="card border border-info rounded"
             *ngIf="paleSelected">

            <div class="card-body">

                <form class="form"
                      [ngClass]="{'form-horizontal': true}">

                    <!-- Todo el palé en la misma ubi, Validar destino -->
                    <div class="row"
                         *ngIf="ubicarPaleEntero">

                        <!-- destinoPale -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': destinoError }">
                                <label class="form-control-label fs-15"> Valida DESTINO de PALE {{paleSelected}}
                                    <span class="text-themecolor monospace ml-1"> {{destino!| mask: '00.00.00.00.00.00'}}</span> :
                                </label>
                                <input type="text"
                                       autofocus
                                       name="uicInput"
                                       [(ngModel)]="destinoPale"
                                       class="form-control"
                                       maxlength="13"
                                       (keydown.enter)="validarDestinoPale()">

                                <div *ngIf="destinoError"
                                     class="form-control-feedback">
                                    <div>Destino incorrecto</div>
                                </div>

                            </div>
                        </div>

                    </div> <!-- row -->



                    <!-- Artículos del palé en diferentes ubicaciones -->
                    <div class="row"
                         *ngIf="!ubicarPaleEntero">

                        <h3 class="text-themecolor">Palé {{paleSelected}}</h3>


                        <!-- ean -->
                        <div *ngIf="muestraEan"
                             class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': eanError }">
                                <label class="form-control-label"> Valida EAN: </label>
                                <input type="text"
                                       autofocus
                                       name="ean"
                                       [(ngModel)]="ean"
                                       class="form-control"
                                       maxlength="13"
                                       (keydown.enter)="validarEan()">

                                <div *ngIf="eanError"
                                     class="form-control-feedback">
                                    <div>EAN erróneo en palé {{paleSelected}}</div>
                                </div>

                            </div>
                        </div>



                        <!-- destinoEan -->
                        <div *ngIf="registroEncontrado.ubicacion"
                             class="col-md-4">
                            <h3 class="text-themecolor"> EAN {{registroEncontrado.artEan}}</h3>

                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': destinoError }">
                                <label class="form-control-label"> Valida DESTINO
                                    <span class="text-themecolor monospace ml-1"> {{registroEncontrado.ubicacion!| mask: '00.00.00.00.00.00'}}</span> :
                                </label>
                                <p class="font-italic text-sm">
                                    <span *ngIf="registroEncontrado.lote"> LOTE
                                        <span class="text-success text-destacado">{{registroEncontrado.lote}} </span> -
                                    </span>
                                    <span class="text-destacado">{{registroEncontrado.cantidad}}</span> Unidades
                                </p>
                                <input type="text"
                                       autofocus
                                       name="uicInput"
                                       [(ngModel)]="destinoEan"
                                       class="form-control"
                                       maxlength="13"
                                       (keydown.enter)="validarDestinoEan()">

                                <div *ngIf="destinoError"
                                     class="form-control-feedback">
                                    <div>Destino incorrecto</div>
                                </div>

                            </div>
                        </div>

                    </div><!-- row -->

                </form>

            </div>


        </div> <!-- card -->
    </div> <!-- col-lg-12 -->



    <!-- Lista de pedidos -->
    <div class="col-md-12 col-xs-12 animated fadeIn fast">

        <div class="card border border-info rounded">

            <div class="card-body">
                <h4 class="card-title text-info">{{entrada.cliNombre}} - {{entrada.albaran}}</h4>
                <h6 class="card-subtitle">Selecciona que vas a ubicar</h6>
                <div class="bootstrap-table">
                    <div class="table-responsive">

                        <div class="table-responsive">
                            <table class="table table-sm table-bordered fs-10">

                                <thead>
                                    <tr>
                                        <th>UBICAR</th>
                                        <th>EAN</th>
                                        <th>Lote</th>
                                        <th style="text-align: center;">Cant.</th>
                                        <th>Destino</th>
                                    </tr>
                                </thead>

                                <!-- Si hay detall y no está ubicado, mostrarlo -->
                                <tbody *ngIf="entrada.detall">
                                    <ng-container *ngFor="let lin of entrada.detall; let i = index">

                                        <tr [ngClass]="{'background-green': lin.pale === paleSelected}">

                                            <td>
                                                <!-- Select palé -->
                                                <span *ngIf="i == 0 || lin.pale != entrada.detall[i-1].pale">
                                                    <a (click)="selectPale(lin)"
                                                       title="Ubicar palé {{ lin.pale}}"
                                                       class="btn btn-primary text-white"> Palé {{ lin.pale}}</a>
                                                </span>
                                            </td>

                                            <td>
                                                {{ lin.artEan }}
                                                <span *ngIf="lin.swDefectuoso == Const.DEFECTUOSO">
                                                    <i title="Defectuoso"
                                                       class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                                </span>
                                            </td>
                                            <td>{{ lin.lote }}</td>
                                            <td style="text-align: right;">
                                                <div class="monospace">
                                                    {{lin.cantidad | number: '0.0-0':'es'}}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="monospace">
                                                    {{lin.ubicacion! | mask: '00.00.00.00.00.00'}}
                                                </div>
                                            </td>

                                        </tr>

                                    </ng-container>
                                </tbody>

                            </table>
                        </div>


                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
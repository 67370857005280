<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="formInfo"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">



                            <!-- uic -->
                            <div class="col-md-3 col-12"
                                 [ngClass]="{ 'has-danger': enviado && f['uic'].errors }">
                                <label class="form-control-label">UIC:</label>
                                <div class="input-group border   rounded">
                                    <div class="input-group-addon"> <i class="fa-solid fa-dolly"></i></div>
                                    <input type="text"
                                           formControlName="uic"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['uic'].errors }">
                                </div>
                                <div *ngIf="enviado && f['uic'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['uic'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['uic'].errors['formatoIncorrecto']">Formato incorrecto</div>

                                    <div *ngIf="formInfo.get('uic')?.errors?.['apiError']">
                                        {{formInfo.get('uic')?.errors?.['apiError'] }}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <hr>


                        <div class="d-flex">
                            <h6 class="card-subtitle ml-4">

                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Buscar</button>
                        </div>


                    </div>
                </form>


            </div>
        </div>
    </div>



    <div class="col-12"
         *ngIf="uicLabel.uic">
        <div class="card border rounded animated fadeIn fast border-dark rounded mt-1 p-1">
            <div class="card-body">

                <div class="row">

                    <div class="col-12">
                        <div class="card bg-light">
                            <div class="card-body">
                                <div class="card-text">

                                    <span class="h3"><i class="fa-solid fa-dolly"></i> {{uicLabel.uic}}</span> {{ uicLabel.cliNombre }} <br>

                                    <table class="table table-bordered table-sm mt-1">
                                        <tbody>
                                            <tr>
                                                <td><b>Creado hace </b> {{ uicLabel.created_at! | friendlyDdmmyy}}</td>
                                                <td><b>Estado </b>
                                                    <span [innerHTML]=" uicLabel.estado | uicLabelEstado"></span>
                                                    desde el {{ uicLabel.updated_at | date:'dd/MM/yyyy HH:mm'}}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div class="row">

                                        <div class="col-12 col-md-6">
                                            <table *ngIf="uicTotales"
                                                   class="table table-bordered table-sm mt-1">
                                                <tbody>
                                                    <tr>
                                                        <th *ngIf="campanyaInfo.camp_cod">Campaña</th>
                                                        <td>{{ campanyaInfo.camp_cod }}
                                                            <small>{{campanyaInfo.descripcion}}</small>
                                                            <span *ngIf="campanyaInfo.campanyaTipo === '1 a 1'"
                                                                  class="text-warning"><br><i>Campaña tipo pedido 1 a 1</i></span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="listUicsPedido && listUicsPedido.length > 0">
                                                        <th>Pedido</th>
                                                        <td>
                                                            {{ listUicsPedido[0].pedido?.pedido_cli }}
                                                            <small>
                                                                <span [innerHTML]=" listUicsPedido[0].pedido?.estado | pedidoEstado"></span>
                                                                el {{ listUicsPedido[0].pedido?.estado_at | date:'dd/MM/yyyy HH:mm'}}
                                                            </small>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="listUicsPedido[0]?.pedido">
                                                        <th></th>
                                                        <td>{{ listUicsPedido[0].pedido?.nombre }} <br>
                                                            {{ listUicsPedido[0].pedido?.direccion }} <br>
                                                            {{ listUicsPedido[0].pedido?.poblacion }} - {{ listUicsPedido[0].pedido?.pais_iso }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>



                                        <div class="col-12 col-md-6">
                                            <table *ngIf="listUics.length"
                                                   class=" tabla-uics table table-bordered table-sm mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>UIC's del pedido {{ listUicsPedido[0].pedido?.pedido_cli }} ({{ listUicsPedido.length}} cajas)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let uic of listUics"
                                                        (click)="seleccionarUIC(uic)">
                                                        <td> <i class="fa-solid fa-box-open"></i> {{ uic }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </div> <!-- row -->
            </div> <!-- card-body -->
        </div>
    </div>

</div>


<!-- listPreparacion -->
<div class="row animated fadeIn fast"
     *ngIf="listPreparacion.length">
    <div class="col-12 d-none d-md-block">

        <div class="card">
            <div class="card-body">
                <h4 class="card-title"> Detalle de la recogida para {{uicLabel.uic}}</h4>

                <h6 class="card-subtitle"
                    data-toggle="tooltip"
                    title="{{uicTotales.usrRecogida.nombre}}">
                    <i class="fa-solid fa-user mr-1"></i> Asignado a {{uicTotales.usrRecogida.username}} el {{listPreparacion[0].created_at | date:'dd/MM/yyyy HH:mm'}}
                </h6>

                <div class="bootstrap-table">

                    <div>
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Sku</th>
                                    <th>EAN</th>
                                    <th>Artículo</th>
                                    <th>Ubicación</th>
                                    <th>Cantidad</th>
                                    <th>Recogido</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let recogida of listPreparacion">
                                    <td>
                                        <img [src]="fotoDir +'/articulos' + imgResized +  recogida.articulo?.foto"
                                             loading="lazy"
                                             class="rounded border"
                                             height="25px">
                                    </td>
                                    <td>{{ recogida.articulo?.sku }}</td>
                                    <td>{{ recogida.articulo?.ean }}</td>
                                    <td>{{ recogida.articulo?.descripcion }}</td>

                                    <td> <span class="monospace">{{ recogida.ubicacion! | mask: '00.00.00.00.00.00'}}</span></td>

                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ recogida.cant_reserva | number: '0.0-0':'es'}}
                                        </div>
                                    </td>

                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 pr-2 mr-20 monospace"
                                             [ngClass]="{
                                                    'bg-green rounded': (recogida.cant_reserva == recogida.cant_recogida) && recogida.cant_recogida! > 0,
                                                    'bg-blue rounded': (recogida.cant_recogida! < recogida.cant_reserva!) && recogida.cant_recogida! > 0
                                                 }">
                                            {{ recogida.cant_recogida | number: '0.0-0':'es'}}
                                        </div>
                                    </td>
                                    <td> <span [innerHTML]=" recogida.estado | uicEstado"></span></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>


                </div>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</div>




<!-- listReparto -->
<div class="row animated fadeIn fast"
     *ngIf="listReparto.length">
    <div class="col-12 d-none d-md-block">

        <div class="card">
            <div class="card-body">
                <h4 class="card-title"> Detalle del reparto para {{uicLabel.uic}}</h4>

                <h6 class="card-subtitle"
                    data-toggle="tooltip"
                    title="{{uicTotales.usrReparto.nombre}}">
                    <i class="fa-solid fa-user mr-1"></i> Asignado a {{uicTotales.usrReparto.username}} el {{listReparto[0].created_at | date:'dd/MM/yyyy HH:mm'}}
                </h6>

                <div class="bootstrap-table">

                    <div>
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Sku</th>
                                    <th>EAN</th>
                                    <th>Artículo</th>
                                    <th>Pedido</th>
                                    <th>A Repartir</th>
                                    <th>Repartido</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let reparto of listReparto">
                                    <td>
                                        <img [src]="fotoDir +'/articulos' + imgResized + reparto.articulo?.foto"
                                             loading="lazy"
                                             class="rounded border"
                                             height="25px">
                                    </td>
                                    <td>{{ reparto.articulo?.sku }}</td>
                                    <td>{{ reparto.articulo?.ean }}</td>
                                    <td>{{ reparto.articulo?.descripcion }}</td>

                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ reparto.cant_pedido | number: '0.0-0':'es'}}
                                        </div>
                                    </td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ reparto.cant_repartir | number: '0.0-0':'es'}}
                                        </div>
                                    </td>

                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 pr-2 mr-20 monospace"
                                             [ngClass]="{
                                                    'bg-green rounded': (reparto.cant_repartir == reparto.cant_repartida) && reparto.cant_repartida! > 0,
                                                    'bg-blue rounded': (reparto.cant_repartida! < reparto.cant_repartir!) && reparto.cant_repartida! > 0
                                                 }">
                                            {{ reparto.cant_repartida | number: '0.0-0':'es'}}
                                        </div>
                                    </td>
                                    <td> <span [innerHTML]=" reparto.estado | repartoEstado"></span></td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colspan="4"></td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ uicTotales.articulos | number: '0.0-0':'es'}}
                                        </div>
                                    </td>
                                    <td colspan="3"></td>
                                </tr>
                            </tfoot>


                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</div>
import { EntradasService } from './../../services/datos/entradas.service';
import { PedidosService } from './../../services/datos/pedidos.service';
import { Component, OnInit } from '@angular/core';

// servicios
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// interfaces & medelos
import { IUsuario } from 'src/app/interfaces/usuario';
import { environment } from 'src/environments/environment';


/**
 * Listado de preparaciones pendientes para el usuario logeado
 */

@Component({
  selector: 'app-inicio-pda',
  templateUrl: './inicio-pda.component.html',
  styleUrls: ['./inicio-pda.component.scss']
})
export class InicioPdaComponent implements OnInit {


  // Intervalo de refresco de la tabla
  refreshInterval: any;
  timeRefresh: number = environment.timeRefreshPda;

  public enviado: boolean = false;
  public loading: boolean = false;

  public preparacionesList: any[] = [];
  public preparacionesList2: any[] = [];
  public campanyasSinEmbalarList: any[] = [];
  public entradasNoUbicadasList: any[] = [];

  public usr: IUsuario = {};

  public prepPendientes: any[] = [];

  constructor(
    private prepracionSrv: PreparacionesService,
    private pedidosSrv: PedidosService,
    private entradaSrv: EntradasService,
    private usrSrv: UsuarioService,
    private notifciSrv: NotificacionesService,

  ) { }

  ngOnInit(): void {
    this.getDatos();

    // Configurar un intervalo para refrescar datos auomáticamente 
    this.refreshInterval = setInterval(() => {
      this.getDatos();
    }, this.timeRefresh); // tiempo para el Refesco automático de los datos  

  }


  ngOnDestroy(): void {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  getDatos() {
    this.usrSrv.getUsr().subscribe(resp => {
      this.usr = resp;
      this.getListaContaRecogidas();
      this.getListContaPrepPedidosUsr();
      this.getCampanyasSinEmbalar();
      this.getEntradasNoUbicadas();
    });
  }


  getListaContaRecogidas() {
    this.loading = true;
    this.prepracionSrv.getListaContaRecogidas(this.usr.id!)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notifciSrv.aviso('error', resp.mensaje);
          this.usrSrv.logOut();
        } else {
          this.preparacionesList = resp.data;
          console.log('__preparacionesList:', this.preparacionesList);
        }
      })

  }



  getListContaPrepPedidosUsr() {
    this.loading = true;
    this.prepracionSrv.getListContaPrepPedidosUsr(this.usr.id!)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          //  this.notifciSrv.aviso('error', resp.mensaje);
          //  this.usrSrv.logOut();
        } else {
          this.preparacionesList2 = resp.data;
          console.log('__preparacionesList2:', this.preparacionesList2);
        }
      })
  }



  getCampanyasSinEmbalar() {
    this.loading = true;
    this.pedidosSrv.getCampanyasSinEmbalar()
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notifciSrv.aviso('error', resp.mensaje);
          this.usrSrv.logOut();
        } else {
          this.campanyasSinEmbalarList = resp.data;
          console.log('__getCampanyasSinEmbalar:', this.campanyasSinEmbalarList);
        }
      })
  }

  getEntradasNoUbicadas() {
    const opcionesList = { swEnStock: '1', swCompleto: '1' }   // swCompleto=1 Todos los artículos de esta entrada están ubicados lógicamente / swEnStock=1 Algún artículo Pendiente de Ubicar fisicamente 
    this.loading = true;

    this.entradaSrv.getLista(opcionesList)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notifciSrv.aviso('error', resp.mensaje);
          this.usrSrv.logOut();
          return
        } else {
          this.entradasNoUbicadasList = resp.data;
          console.log('__getEntradasNoUbicadas:', this.entradasNoUbicadasList);
        }
      })

  }



  //// AUX  ////

  // Definir los textos en plural
  recogidasPluralMap = {
    '=0': 'Ninguna recogida pendiente',
    '=1': '1 recogida pendiente',
    'other': '# recogidas pendientes'
  };

  preparacionesPluralMap = {
    '=0': 'Ninguna preparacion pendiente',
    '=1': '1 preparación pendiente',
    'other': '# preparaciones pendientes'
  };

  campanyasPluralMap = {
    '=0': 'Ninguna campaña sin embalaje',
    '=1': '1 campaña sin embalaje',
    'other': '# campañas sin embalajes'
  };

  entradasPluralMap = {
    '=0': 'Ninguna entrada sin confirmar',
    '=1': '1 entrada pendiente de confirmar',
    'other': '# entradas pendientes de confirmar'
  };



}

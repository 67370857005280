// Módulos
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// librerias y módulos externos
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// Pipes
import { FriendlyTimestampPipe } from '../pipes/friendly-timestamp.pipe';
import { FriendlyDdmmyyPipe } from '../pipes/friendly-ddmmyy.pipe';
import { iconCheckPipe } from '../pipes/icon-check.pipe';
import { iconFechaCaducadaPipe } from '../pipes/icon-fecha-caducada.pipe';
import { ubiTipoFamiliaPipe } from '../pipes/ubiTipo-familia.pipe';
import { CampanyaEstadoPipe } from '../pipes/campanya-estado.pipe';
import { PedidoEstadoPipe } from '../pipes/pedido-estado.pipe';
import { CarritoEstadoPipe } from '../pipes/carrito-estado.pipe';
import { UicEstadoPipe } from '../pipes/uic-estado.pipe';
import { articuloOrigenPipe } from '../pipes/articulo-origen';
import { RepartoEstadoPipe } from '../pipes/reparto-estado.pipe';
import { UicLabelEstadoPipe } from '../pipes/uic-label-estado.pipe';
import { EuroCurrencyPipe } from '../pipes/euro-currency.pipe';


// Componentes
import { HeaderComponent } from './header/header.component';
import { BreadcrumbsComponent } from './bead-crums/bead-crums.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LocalConfigComponent } from './local-config/local-config.component';
import { UsrPerfilComponent } from './usr-perfil/usr-perfil.component';
import { UicLabelsComponent } from './uic-labels/uic-labels.component';
import { UicInfoComponent } from './uic-info/uic-info.component';
import { NgxMaskModule } from 'ngx-mask';
import { UbicacionesClienteComponent } from './_componentes/ubicaciones-cliente/ubicaciones-cliente.component';
import { UbicacionesArticuloComponent } from './_componentes/ubicaciones-articulo/ubicaciones-articulo.component';
import { EntradaInfoComponent } from './_componentes/entrada-info/entrada-info.component';
import { CampanyaEstadoPreparacionPipe } from '../pipes/campanya-estado-preparacion.pipe';
import { PreparacionEstadoPipe } from '../pipes/preparacion-estado.pipe';
import { ImprimePackingListPipe } from '../pipes/imprime-packing-list.pipe';


@NgModule({
  declarations: [

    // pipe's   
    FriendlyTimestampPipe,
    FriendlyDdmmyyPipe,
    iconCheckPipe,
    iconFechaCaducadaPipe,
    ubiTipoFamiliaPipe,
    CampanyaEstadoPipe,
    CampanyaEstadoPreparacionPipe,
    CarritoEstadoPipe,
    UicEstadoPipe,
    articuloOrigenPipe,
    UicLabelEstadoPipe,
    PedidoEstadoPipe,
    ImprimePackingListPipe,
    RepartoEstadoPipe,
    PreparacionEstadoPipe,
    EuroCurrencyPipe,

    // Componentes
    HeaderComponent,
    BreadcrumbsComponent,
    SidebarComponent,
    LocalConfigComponent,
    UsrPerfilComponent,
    UicLabelsComponent,
    UicInfoComponent,

    EntradaInfoComponent,
    UbicacionesClienteComponent,
    UbicacionesArticuloComponent,

  ],
  exports: [

    // pipe's  
    FriendlyTimestampPipe,
    FriendlyDdmmyyPipe,
    iconCheckPipe,
    iconFechaCaducadaPipe,
    ubiTipoFamiliaPipe,
    CampanyaEstadoPipe,
    CampanyaEstadoPreparacionPipe,
    CarritoEstadoPipe,
    UicEstadoPipe,
    articuloOrigenPipe,
    UicLabelEstadoPipe,
    PedidoEstadoPipe,
    ImprimePackingListPipe,
    RepartoEstadoPipe,
    PreparacionEstadoPipe,
    EuroCurrencyPipe,

    // Componentes
    HeaderComponent,
    BreadcrumbsComponent,
    SidebarComponent,
    EntradaInfoComponent,

    UbicacionesClienteComponent,
    UbicacionesArticuloComponent,



  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule,     //  se utiliza el <router-outlet>
    NgxDatatableModule,

  ]

})
export class SharedModule { }

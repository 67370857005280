import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

// servicios
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías y pipes
import { FriendlyTimestampPipe } from 'src/app/pipes/friendly-timestamp.pipe';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService
  ) { }

  public loginForm = this.fb.group({
    username: [localStorage.getItem('username') || '', [Validators.required]],
    password: ['', Validators.required],
    remember: [localStorage.getItem('recordar') === 'true' || false]
  })

  ngOnInit(): void {
  }

  enviar() {
    const frm = this.loginForm;
    this.loading = true;
    this.usuarioSrv.logIn(frm.value)
      .subscribe(respuesta => {
        this.loading = false;
        if (respuesta.error) {
          this.notificacionesSrv.aviso('error', respuesta.mensaje);
        } else {
          let mensajePiped: string = '';
          if (this.usuarioSrv.userdata.timestamp) {
            mensajePiped = '<br>Tu última conexión fue hace ' + new FriendlyTimestampPipe().transform(this.usuarioSrv.userdata.timestamp!);
          }
          this.notificacionesSrv.aviso('info', `Bienvenid@ ${respuesta.data.userdata.nombre!} ${mensajePiped}`);
          if (frm.get('remember')?.value) {
            localStorage.setItem('username', frm.get('username')!.value!);
            localStorage.setItem('recordar', 'true');
          } else {
            localStorage.removeItem('username');
            localStorage.removeItem('recordar');
          }

          console.log('__usuarioSrv.userdata:', this.usuarioSrv.userdata);

          if (this.usuarioSrv.userdata.rol == "usuario") {
            this.router.navigateByUrl('/pda');
          } else {
            this.router.navigateByUrl('/panel');
          }

          // ir a un landingPage en función de su rol
          switch (this.usuarioSrv.userdata.rol) {
            case 'usuario':
              this.router.navigateByUrl('/pda');
              break;
            case 'admin':
              this.router.navigateByUrl('/panel');
              break;
            default:
              this.notificacionesSrv.aviso('warning', `Esta Intranet no es accesible para tu rol de acceso como ${this.usuarioSrv.userdata.rol}.`);
              this.usuarioSrv.logOut();
              break;
          }

        }
      })
  }

  recordarPsw() {
    Swal.fire({
      title: 'Recordar contraseña',
      input: "text",
      inputAttributes: {
        autocapitalize: 'off'
      },
      icon: 'question',
      html: 'No te preocupes, a todos nos pasa🙄.<br><br> Introduce tu dirección de eMail, si existe en nuestra base de datos, te enviaremos las instrucciones para cambiarla',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
    }).then((result) => {
      if (result.isConfirmed) {
        const email = result.value;

        console.log('Confirmado acción, result ', result);

        if (email) {
          this.notificacionesSrv.aviso('info', `Se pide pswordReset para ${email}`);
          this.notificacionesSrv.aviso('warning', `recordarPsw(), Este método no está implementado`);
          // Aquí continuar con el código para enviar el email
        }
      }
    });
  }
}


<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="eanForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- ean -->
                            <div class="col-md-6 col-12"
                                 [ngClass]="{ 'has-danger': enviado && f['ean'].errors }">
                                <label class="form-control-label">EAN:</label>
                                <div class="input-group border rounded">

                                    <div class="input-group-addon"> <i class="fa-solid fa-boxes-stacked"></i></div>
                                    <input type="text"
                                           formControlName="ean"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['ean'].errors }">

                                </div>
                                <div *ngIf="enviado && f['ean'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['ean'].errors['required']">Obligatorio</div>

                                    <div *ngIf="eanForm.get('ean')?.errors?.['apiError']">
                                        {{eanForm.get('ean')?.errors?.['apiError'] }}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <hr>


                        <div class="d-flex">
                            <h6 class="card-subtitle ml-4">

                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Buscar</button>
                        </div>


                    </div>
                </form>


            </div>
        </div>
    </div>





    <!-- Lista del Stock en ubicacion -->
    <div *ngIf="stockList.length"
         class="col-md-12 col-xs-12 animated fadeIn fast">

        <div class="card border border-info rounded">
            <div class="card-body padding-1">
                <h4 class="card-title text-info">Ubicaciones con EAN {{ean}} <br>
                    <b>{{ stockList[0].clienteNombre }}</b>
                </h4>
                <div class="bootstrap-table">


                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead class="fs-14">
                                <tr>
                                    <th>Ubicación</th>
                                    <th>Cant.</th>
                                    <th>Reserv.</th>
                                    <th>Lote</th>
                                    <th>Caduca</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody class="fs-12">
                                <ng-container *ngFor="let lin of stockList; let i = index">
                                    <tr>
                                        <td>
                                            {{ lin.ubiCodigo! | mask:'00.00.00.00.00.00' }}

                                        </td>
                                        <td style="text-align: right;  ">
                                            <div class=" monospace">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; ">
                                            <div class=" monospace">
                                                {{lin.cantidadReservada | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td>{{ lin.lote }}</td>
                                        <td>{{ lin.fechaCaducidad }}</td>
                                    </tr>


                                </ng-container>
                            </tbody>

                        </table>
                    </div>



                </div>
            </div>

            <div class="card-body padding-1">
                <div class="row">
                    <div class="col-4">
                        <div *ngIf="stockList[0].articulo && stockList[0].articulo.foto !== '_noFoto.png'"
                             class="text-center">
                            <img [src]="fotoDir + '/articulos' + imgResized + stockList[0].articulo.foto"
                                 loading="lazy"
                                 alt="Foto"
                                 class="foto pb-1"
                                 title="foto">
                        </div>
                    </div>
                    <div class="col-8">
                        <span class="text-info">SKU: {{stockList[0].articulo?.sku}}</span><br>
                        {{stockList[0].articulo?.descripcion}}
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>






</div>
<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="ubiForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- codUbi -->
                            <div class="col-md-3 col-12"
                                 [ngClass]="{ 'has-danger': enviado && f['codUbi'].errors }">
                                <label class="form-control-label">Ubicación:</label>
                                <div class="input-group border rounded">

                                    <div class="input-group-addon"> <i class="fa-solid fa-location-pin"></i></div>
                                    <input type="text"
                                           formControlName="codUbi"
                                           (input)="cambiaUbi()"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['codUbi'].errors }">

                                </div>
                                <div *ngIf="enviado && f['codUbi'].errors"
                                     class="form-control-feedback">
                                    <div *ngIf="f['codUbi'].errors['required']">Obligatorio</div>
                                    <div *ngIf="f['codUbi'].errors['formatoIncorrecto']">Formato incorrecto</div>

                                    <div *ngIf="ubiForm.get('codUbi')?.errors?.['apiError']">
                                        {{ubiForm.get('codUbi')?.errors?.['apiError'] }}
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-6 col-12">
                                <span class="fs-11">{{codUbiDecript}}</span>
                            </div>


                        </div>
                        <hr>


                        <div class="d-flex">
                            <h6 class="card-subtitle ml-4">

                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Buscar</button>
                        </div>


                    </div>
                </form>


            </div>
        </div>
    </div>





    <!-- Lista del Stock en ubicacion -->
    <div *ngIf="stockList.length"
         class="col-md-12 col-xs-12 animated fadeIn fast">

        <div class="card border border-info rounded">
            <div class="card-body padding-1">
                <h4 class="card-title text-info">Artículos en {{codUbi | mask: '00.00.00.00.00.00'}}<br>
                    <b>{{ stockList[0].clienteNombre }}</b>
                </h4>
                <div class="bootstrap-table">

                    <div class="table-responsive">
                        <table class="table table-sm table-hover">

                            <thead class="fs-13">
                                <tr>
                                    <th>SKU</th>
                                    <th>EAN</th>
                                    <th>Cant.</th>
                                    <th>Reserv.</th>
                                    <th>Lote</th>
                                    <th>Caduca</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody class="fs-10">
                                <ng-container *ngFor="let lin of stockList; let i = index">
                                    <tr [ngClass]="{ 'row-color-2': i % 2 === 0, 'row-color-1': i % 2 !== 0 }">
                                        <td>
                                            {{ lin.articuloSku }}
                                            <span *ngIf="lin.articuloSwDefectuoso == Const.DEFECTUOSO">
                                                <i title="Defectuoso"
                                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                            </span>
                                        </td>
                                        <td>{{ lin.articuloEan }}</td>
                                        <td style="text-align: right;">
                                            <div class="monospace">
                                                {{lin.cantidad | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right;">
                                            <div class="monospace">
                                                {{lin.cantidadReservada | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td>{{ lin.lote }}</td>
                                        <td>
                                            <span [innerHTML]="lin.fechaCaducidad | iconFechaCaducada"></span>
                                        </td>
                                    </tr>
                                    <tr [ngClass]="{ 'row-color-2': i % 2 === 0, 'row-color-1': i % 2 !== 0 }">
                                        <td colspan="6"><i>{{ lin.articuloDescripcion }}</i></td>
                                    </tr>
                                </ng-container>
                            </tbody>


                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>






</div>
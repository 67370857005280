import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// servicios
import { UsuarioService } from './usuario.service';
import { AlmacenesService } from './almacenes.service';

// interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IUbicacion } from 'src/app/interfaces/ubicacion';

// librerías y entrono
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UbicacionesService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private almacenesSrv: AlmacenesService
  ) { }

  getUbicacion(id: number) {
    const path = `${this.apiURL}/ubicaciones/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getLista() {
    // Devulve lista simple
    const path = `${this.apiURL}/ubicaciones/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getTotalUbis() {
    // Devulve total ubicaciones por almacén
    const path = `${this.apiURL}/ubicaciones/totalUbis`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getListaFiltrada(_opciones: IListaOpciones = {}) {
    // Devulve lista filtrada
    const path = `${this.apiURL}/ubicaciones/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  new(formData: any) {
    const path = `${this.apiURL}/ubicaciones/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  /**
   * ubiLabel(data: any)
   * Imprime etiquetas de ubicaciones
   **/
  ubiLabel(data: any) {
    const path = `${this.apiURL}/ubicaciones/ubiLabel`;
    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  update(ubicacion: IUbicacion) {
    const path = `${this.apiURL}/ubicaciones/update/${ubicacion.id}`;

    return this.http.post<IApiResp>(path, ubicacion)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/ubicaciones/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  buscaSitio(data: any) {

    const medidas = {
      id_almacen: data.id_almacen || null,
      alto: data.alto || null,
      ancho: data.ancho || null,
      largo: data.largo || null,
    };

    const path = `${this.apiURL}/ubicaciones/buscaSitio`;

    return this.http.post<IApiResp>(path, medidas)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  ubicaPale(data: any) {
    const path = `${this.apiURL}/ubicaciones/ubicaPale`;

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }


  ubicaArticulo(data: any) {
    const path = `${this.apiURL}/ubicaciones/ubicaArticulo`;

    console.log('ubicaArticulo service, entrada ', data)


    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }

  getListArticulo(id: number) {
    const path = `${this.apiURL}/ubicaciones/ubiListArticulo/${id}`;

    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }


  getListCliente(id: number) {
    const path = `${this.apiURL}/ubicaciones/ubicacionesCliente/${id}`;

    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }



  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}

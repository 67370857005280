<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor"
            style="display: inline;">Distribuir EAN </h3>
        <h5 style="display: inline;font-style: italic;">Campaña: {{totales.campanya?.camp_cod}}</h5>
        <h3 class="text-themecolor">
            <i class="fa-solid fa-dolly"></i> {{uic}}
        </h3>
    </div>
</div>


<div class="animated fadeIn fast">

    <div class="row">

        <div class="col-lg-12">
            <div class="card">

                <div class="card-body">

                    <table>
                        <ng-container>
                            <tr>
                                <td>
                                    <div *ngIf="artic.articulo && artic.articulo.foto !== '_noFoto.png'"
                                         class="text-center">
                                        <img [src]="fotoDir + '/articulos' + imgResized + artic.articulo.foto"
                                             loading="lazy"
                                             alt="Foto"
                                             class="foto"
                                             title="foto">
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <strong>Sku:</strong> {{ artic.articulo?.sku }}
                                    </div>
                                    <div>
                                        <strong>EAN:</strong> {{ artic.articulo?.ean }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="custom-td"
                                    colspan="3"
                                    [title]="artic.articulo?.descripcion">
                                    {{artic.articulo?.descripcion}}
                                </td>

                            </tr>

                        </ng-container>

                    </table>

                    <hr style="margin: 3px;">



                    <div>
                        <p style="display: flex; justify-content: space-between; align-items: flex-end;">
                            <span>

                                <h3>
                                    <span class="text-info">{{cantEnElCarro | number: '0.0-0':'es'}}</span>
                                    <span class="fs-15"> Unidades en el carro </span>
                                </h3>


                            </span>

                            <span>
                                <button (click)="volverClick()"
                                        [disabled]="loading"
                                        class="btn btn-primary btn-sm"
                                        style="height: 54px">
                                    <i class="fa-solid fa-circle-left fa-2xl"></i> Artículos
                                </button>
                            </span>

                        </p>
                    </div>




                </div>

            </div> <!-- card -->
        </div>

    </div><!-- row -->



    <div class="row">

        <div class="col-lg-12">

            <div class="card">
                <div class="card-body">

                    <!-- <div>
                        <p style="display: flex; justify-content: space-between; align-items: flex-end;">
                            <span>
                                <span>Cajetín: <span class="font-weight-bold display-4 text-success">{{cajetinActual.cajetin}}</span></span>
                            </span>
                            <span>
                                <span>Repartido: <span class="display-4 text-info">{{cajetinActual.cant_repartida}}</span></span>
                                <span class="font-weight-bold display-4 "> / </span>
                                <span class="display-4 text-info">{{cajetinActual.cant_repartir | number: '0.0-0':'es'}}</span>
                            </span>
                        </p>
                    </div>
 -->

                    <div>
                        <p style="display: flex; justify-content: space-between; align-items: flex-end;">
                            <span>
                                <span>Cajetín:
                                    <span class="font-weight-bold text-success display-large">
                                        {{ cajetinActual.cajetin }}
                                    </span>
                                </span>
                            </span>
                            <span>
                                <span>Repartido:
                                    <span class="text-info display-large">
                                        {{ cajetinActual.cant_repartida }}
                                    </span>
                                </span>
                                <span class="font-weight-bold display-large"> / </span>
                                <span class="text-info display-large">
                                    {{ cajetinActual.cant_repartir | number: '0.0-0':'es' }}
                                </span>
                            </span>
                        </p>
                    </div>





                    <form>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': inputUnidadesError }">
                                    <label class="form-control-label">Cantidad:</label>
                                    <input id='inputUnidades'
                                           name='inputUnidades'
                                           [(ngModel)]="inputUnidades"
                                           type="number"
                                           class="form-control">
                                    <div *ngIf="inputUnidadesError"
                                         class="text-danger">
                                        Entre 1 y {{cajetinActual.cant_repartir-cajetinActual.cant_repartida | number: '0.0-0':'es'}}
                                    </div>
                                </div>

                            </div>

                            <!-- inputCaja -->
                            <div class="col-6">
                                <label for="inputCaja">Leido:</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i [ngClass]="{'fa fa-spinner fa-spin': loading}"
                                           class="input-group-text"></i>
                                    </div>
                                    <input name='inputCaja'
                                           [(ngModel)]="inputCaja"
                                           (keydown.enter)="inputCajaEnter()"
                                           type="text"
                                           class="form-control"
                                           placeholder="Cajetín/Caja"
                                           #inputCajaField>
                                </div>
                                <small class="form-text text-muted">7777: Salta cajetín / 8888: Cambia EAN</small>
                            </div>

                        </div>
                    </form>
                </div>
            </div>


            <div class="card">
                <div class="card-body">

                    <div style="display: flex; justify-content: space-between; align-items: flex-end;">

                        <span class="custom-input">
                            <span class="font-weight-bold text-warning mr-1">{{ cajetinActual.max_cajas }}</span>
                            <span class="mr-1">{{ cajetinActual.max_cajas == 1 ? 'Caja' : 'Cajas' }}</span>
                            <button (click)="addCaja()"
                                    [disabled]="loading"
                                    class="btn btn-primary btn-sm">
                                <i class="fa-solid fa-circle-plus fa-2xl"></i> Añadir
                            </button>
                        </span>

                        <span>
                            <div class="input-group">
                                <select class="custom-select"
                                        [(ngModel)]="labelSelect"
                                        style="height: 54px">
                                    <option *ngFor="let i of generaArrayAux()"
                                            [value]="i">{{ i }}</option>
                                </select>
                                <div class="input-group-append">
                                    <button (click)="prnEtiqueta()"
                                            [disabled]="loading"
                                            class="btn btn-primary btn-sm"
                                            type="button"
                                            style="height: 54px">
                                        <i class="fa-solid fa-print fa-2xl"></i> Etiq. caja
                                    </button>
                                </div>
                            </div>
                        </span>

                    </div> <!-- flex -->

                </div>

            </div> <!-- card -->
        </div>

    </div><!-- row -->





    <div class="row">

        <div class="col-lg-12">

            <div class="card">
                <div class="card-body padding-0">
                    <table class="table table-bordered table-striped">
                        <thead class="fs-10">
                            <tr>
                                <th>Cajetín</th>
                                <th>Uic</th>
                                <th>Pedido</th>
                                <th>Repartido</th>
                                <th>Repartir</th>
                                <th>Faltan</th>
                            </tr>
                        </thead>
                        <tbody class="fs-12">
                            <tr *ngFor="let item of repartos"
                                [ngClass]="{'green-bg': (item.cant_repartir-item.cant_repartida) == 0, 'blue-bg': item.cajetin === cajetinActual.cajetin}">
                                <td class="fs-15 text-rightfont-weight-bold text-success">{{ item.cajetin }}</td>
                                <td class="fs-10">{{ item.uic }}</td>
                                <td class="text-right">{{ item.cant_pedido | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ item.cant_repartida | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ item.cant_repartir | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ item.cant_repartir-item.cant_repartida | number: '0.0-0':'es' }}</td>
                            </tr>

                            <tr class="total-row fs-12">
                                <td></td>
                                <td></td>
                                <td class="text-right">{{ sumaCantidadesPedido | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ sumaCantidadesRepartidas | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ sumaCantidadesARepartir | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ sumaCantidadesARepartir-sumaCantidadesRepartidas | number: '0.0-0':'es' }}</td>
                            </tr>


                            <!--     OTROS CARROS   -->

                            <tr *ngFor="let item2 of repartosDeOtros"
                                [ngClass]="{
                                'green-bg': (item2.cant_repartir - item2.cant_repartida) == 0, 
                                'red-bg': (item2.cant_repartir - item2.cant_repartida) !== 0 
                            }">
                                <td class="text-right">{{ item2.cajetin }}</td>
                                <td>{{ item2.uic }}</td>
                                <td class="text-right">{{ item2.cant_pedido | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ item2.cant_repartida | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ item2.cant_repartir | number: '0.0-0':'es' }}</td>
                                <td class="text-right">{{ item2.cant_repartir-item2.cant_repartida | number: '0.0-0':'es' }}</td>
                            </tr>


                        </tbody>
                        <!-- <tr class="total-row">
                            <td></td>
                            <td>{{ sumaCantidadesRepartidas }}</td>
                            <td>{{ sumaCantidadesARepartir }}</td>
                            <td>{{ sumaCantidadesARepartir-sumaCantidadesRepartidas }}</td>
                        </tr> -->
                    </table>

                </div>
            </div>
        </div>
    </div>



    <!-- Cajas -->

    <div *ngIf="sumaArticulosCajas"
         class="row">

        <div class="col-lg-12">

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Cajas del cajetín {{cajetinActual.cajetin}}</h4>
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Caja</th>
                                <th>EAN</th> 
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of listArticulosCajas; let i = index">
                                <!-- No repetir el numero de caja -->
                                <td class="text-right font-weight-bold text-warning">
                                    <ng-container *ngIf="i === 0 || item.caja_num != listArticulosCajas[i-1].caja_num; else emptyCell">
                                        {{item.caja_num}}
                                    </ng-container>
                                    <ng-template #emptyCell>&nbsp;</ng-template>
                                </td>
                                <td>{{ item.articulo.ean }}</td> 
                                <td class="text-right">{{ item.cantidad | number: '0.0-0':'es' }}</td>
                            </tr>

                            <tr class="total-row">
                                <td></td> 
                                <td></td>
                                <td class="text-right">{{ sumaArticulosCajas | number: '0.0-0':'es' }}</td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>


</div>